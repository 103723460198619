import React, { Component } from 'react';
import { faCalendarAlt, faCheckCircle } from '@fortawesome/fontawesome-free-regular';
import { faCheck, faTimes } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { snakeCase } from 'change-case';
import InputMask from 'react-input-mask';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import ru from 'date-fns/locale/ru';
import { ReactDadata } from 'react-dadata';
import { format, parseISO, subYears } from 'date-fns';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { scroller } from 'react-scroll';
import LoadingSpinner from '../Layout/LoadingSpinner';
import getMask from '../Utils/Masks';
import { customFetch, customSelectStyle, switchLanguage } from '../Utils/Helpers';
import getValidation from '../Utils/Validation';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import AutoResizingTextarea from '../Utils/UiComponents/AutoResizingTextarea';
import withUserInfoStoreStore from '../Utils/withUserInfoStoreStore';

const optionConfirmed = [
  { value: 'yes', label: 'Подтвержден' },
  { value: 'no', label: 'Не подтвержден' },
  { value: 'under_review', label: 'На проверке' },
  { value: 'no_need', label: 'Не требуется' },
  { value: 'need_update', label: 'Требуется уточнение' },
];

const optionType = [
  { value: 'natural', label: 'Физ. лицо' },
  { value: 'self_employed', label: 'Физ. лицо (самозанятый)' },
  { value: 'individual', label: 'Индивидуальный предприниматель' },
  { value: 'legal', label: 'Юр. лицо' },
];
class PaymentInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkingStatusInFNS: false,
      uploading: false,
      fieldsForAgentType: [],
      alphaBankSelfemployedId: null,
      formFields: {
        id: {
          value: '',
        },
        confirmed: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'select',
        },
        newEmail: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'email',
        },
        agentTypeCode: {
          value: 'natural',
        },
        actualMatchLegal: {
          value: false,
        },
        lastName: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'name',
        },
        firstName: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'name',
        },
        middleName: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'name',
        },
        chiefLastName: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'name',
        },
        chiefFirstName: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'name',
        },
        chiefMiddleName: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'name',
        },
        chiefPosition: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'name',
        },
        chiefEmail: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'email',
        },
        chiefPhone: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'phone',
        },
        chiefLinkToCrm: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: false,
        },
        chiefPassportSerial: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'passportSerial',
        },
        chiefPassportNumber: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'passportNumber',
        },
        chiefPassportDate: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'date',
        },
        chiefPassportIssuedBy: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'passportIssuedBy',
        },
        birthday: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'date',
        },
        passportSerial: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'passportSerial',
        },
        passportNumber: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'passportNumber',
        },
        passportDate: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'date',
        },
        passportIssuedByCode: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'plain',
        },
        passportIssuedBy: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'passportIssuedBy',
        },
        birthdayAddress: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'passportIssuedBy',
        },
        userAddress: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'address',
        },
        legalAddress: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'address',
        },
        actualAddress: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'address',
        },
        organizationName: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'plain',
        },
        ogrn: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'ogrn',
        },
        ogrnip: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'ogrnip',
        },
        inn: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'inn',
        },
        snils: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'snils',
        },
        kpp: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'kpp',
        },
        bic: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'bic',
        },
        correspondentAccount: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'paymentAccount',
        },
        settlementAccount: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'paymentAccount',
        },
        bankName: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'bankName',
        },
        paymentInfoComment: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'comment',
        },
        paymentCard: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'paymentCard',
        },
      },
      documents: {
        passport_page_one: {
          name: '',
          file: null,
          downloaded: false,
          errorMessage: null,
          validationType: 'image',
          path: '',
        },
        passport_page_two: {
          name: '',
          file: null,
          downloaded: false,
          errorMessage: null,
          validationType: 'image',
          path: '',
        },
        inn: {
          name: '',
          file: null,
          downloaded: false,
          errorMessage: null,
          validationType: 'image',
          path: '',
        },
        snils: {
          name: '',
          file: null,
          downloaded: false,
          errorMessage: null,
          validationType: 'image',
          path: '',
        },
        registration_certificate: {
          name: '',
          file: null,
          downloaded: false,
          errorMessage: null,
          validationType: 'image',
          path: '',
        },
        offer: {
          name: '',
          file: null,
          downloaded: false,
          errorMessage: null,
          validationType: 'image',
          path: '',
        },
        self_employed_document: {
          name: '',
          file: null,
          downloaded: false,
          errorMessage: null,
          validationType: 'image',
          path: '',
        },
      },
      offerBlank: '',
    };
  }

  componentDidMount() {
    const { formFields } = this.state;
    const { agent } = this.props;
    fetch(`${process.env.REACT_APP_API_DOMAIN}/admin_documents/offer_for_legal`, {
      method: 'get',
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ offerBlank: response.path });
      })
      .catch((err) => {
        console.log(err);
      });
    if (agent.id) {
      Object.keys(formFields).forEach((name) => {
        if (snakeCase(name) in agent) {
          if (agent[snakeCase(name)]) {
            formFields[name].value = agent[snakeCase(name)];
          }
          if (agent.alpha_bank_selfemployed_id) {
            this.setState({ alphaBankSelfemployedId: agent.alpha_bank_selfemployed_id });
          }
        }
      });
      this.setState({ formFields }, () => {
        this.downloadDocuments();
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { agent } = this.props;
    if ((prevProps.agent.id !== agent.id) && agent.id) {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((name) => {
        if (snakeCase(name) in agent) {
          if (agent[snakeCase(name)]) {
            formFields[name].value = agent[snakeCase(name)];
          }
        }
      });
      this.setState({ formFields }, () => {
        this.downloadDocuments();
      });
    }
  }

  downloadDocuments = () => {
    const { showModalInfo } = this.context;
    const { formFields } = this.state;
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/documents?agentId=${formFields.id.value}`, {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((newDocuments) => {
        if (newDocuments !== 'not_found') {
          const { documents } = this.state;
          Object.keys(newDocuments).forEach((document) => {
            if (newDocuments[document]) {
              documents[document].name = newDocuments[document].split('/').splice(-1)[0];
              documents[document].downloaded = true;
              documents[document].path = newDocuments[document];
            }
          });
          this.setState({ documents });
        }
      })
      .catch(() => {
        showModalInfo('Ошибка загрузки документов');
      });
  };

  handleChangeInput = (e, translate = false, upperCase = false) => {
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    if (translate) {
      value = switchLanguage(e.target.value);
    }
    if (upperCase) {
      value = value.toUpperCase();
    }
    const { formFields } = this.state;
    formFields[e.target.name].value = value;
    if (formFields[e.target.name].errorVisible) {
      formFields[e.target.name].errorVisible = false;
    }
    this.setState({ formFields });
  };

  handleChangePaymentCard = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    const { formFields } = this.state;
    formFields[e.target.name].value = value;
    if (formFields[e.target.name].errorVisible) {
      formFields[e.target.name].errorVisible = false;
    }
    this.setState({ formFields });
  };

  handleChangeDate = (date, field) => {
    const { formFields } = this.state;
    formFields[field].value = format(date, "yyyy-MM-dd'T'HH:mm:ss");
    if (date) {
      formFields[field].errorVisible = false;
    }
    this.setState({ formFields });
  };

  handleChangeAddress = (query, name) => {
    const { formFields } = this.state;
    formFields[name].value = query;
    if (name === 'actualAddress' && formFields.actualMatchLegal.value) {
      formFields.actualAddress.value = query;
      formFields.actualAddress.errorVisible = false;
    }
    if (formFields[name].errorMessage) {
      formFields[name].errorVisible = false;
    }
    this.setState({ formFields });
  };

  handleChangeBankName = (query) => {
    const { formFields } = this.state;
    formFields.bankName.value = query.value;
    formFields.bic.value = query.data.bic;
    formFields.correspondentAccount.value = query.data.correspondent_account;
    if (formFields.bankName.errorMessage) {
      formFields.bankName.errorVisible = false;
    }
    if (formFields.bic.errorMessage) {
      formFields.bic.errorVisible = false;
    }
    if (formFields.correspondentAccount.errorMessage) {
      formFields.correspondentAccount.errorVisible = false;
    }
    this.setState({ formFields });
  };

  handleChangePassportIssuedBy = (query, field) => {
    const { formFields } = this.state;
    formFields[field].value = query.value;
    if (formFields[field].errorMessage) {
      formFields[field].errorVisible = false;
    }
    if (field === 'passportIssuedBy' && query.data) {
      formFields.passportIssuedByCode.value = query.data.code;
    }
    this.setState({ formFields });
  };

  handleSetActualMatchLegal = (e) => {
    const value = e.target.checked;
    const { formFields } = this.state;
    formFields.actualMatchLegal.value = value;
    formFields.actualAddress.value = formFields.legalAddress.value;
    this.setState({ formFields });
  };

  handleChangeFile = (e) => {
    const { documents } = this.state;
    if (e.target.files[0]) {
      documents[e.target.name].name = e.target.files[0].name;
      documents[e.target.name].file = e.target.files[0];
      documents[e.target.name].downloaded = false;
    }
    documents[e.target.name].errorMessage = getValidation(documents[e.target.name].name, documents[e.target.name].validationType);
    this.setState({ documents });
  };

  handleDeleteFile = (fileName) => {
    const { documents } = this.state;
    documents[fileName].name = '';
    documents[fileName].file = null;
    documents[fileName].downloaded = false;
    documents[fileName].errorMessage = null;
    documents[fileName].path = '';
    this.setState({ documents });
  };

  handleValidate = (e) => {
    const { formFields } = this.state;
    const { value } = formFields[e.target.name];
    formFields[e.target.name].errorMessage = getValidation(value, formFields[e.target.name].validationType);
    if (formFields[e.target.name].errorMessage) {
      formFields[e.target.name].errorVisible = true;
    }
    this.setState({ formFields });
  };

  validateAllFields = () => {
    const { formFields, documents } = this.state;
    let validationSuccess = true;
    let fields = [];
    let documentsArray = [];
    let errorBlockName = false;
    if (formFields.agentTypeCode.value === 'natural' || formFields.agentTypeCode.value === 'self_employed') {
      fields = [
        'lastName', 'firstName', 'middleName', 'inn', 'snils', 'paymentInfoComment', 'birthday', 'passportIssuedBy', 'agentTypeCode', 'confirmed',
        'passportSerial', 'passportNumber', 'passportDate', 'userAddress', 'bankName', 'paymentCard', 'birthdayAddress', 'passportIssuedByCode',
        'bic', 'correspondentAccount', 'settlementAccount',
      ];
      if (formFields.agentTypeCode.value === 'natural') {
        documentsArray = ['inn', 'snils', 'passport_page_one', 'passport_page_two'];
      }
      if (formFields.agentTypeCode.value === 'self_employed') {
        documentsArray = ['inn', 'snils', 'passport_page_one', 'passport_page_two', 'self_employed_document'];
      }
    } else if (formFields.agentTypeCode.value === 'legal') {
      fields = [
        'chiefFirstName', 'paymentInfoComment',
        'chiefPhone', 'legalAddress', 'actualAddress', 'organizationName', 'ogrn', 'inn', 'kpp', 'agentTypeCode', 'confirmed',
        'bic', 'correspondentAccount', 'settlementAccount', 'bankName', 'chiefMiddleName',
        'chiefLastName', 'chiefPosition', 'chiefEmail', 'lastName', 'firstName', 'middleName', 'chiefPassportSerial', 'chiefPassportNumber', 'chiefPassportDate', 'chiefPassportIssuedBy',
      ];
      documentsArray = ['inn', 'registration_certificate', 'offer', 'passport_page_one', 'passport_page_two'];
    } else if (formFields.agentTypeCode.value === 'individual') {
      fields = [
        'ogrnip', 'organizationName', 'inn', 'bic', 'agentTypeCode', 'confirmed', 'lastName', 'firstName', 'middleName',
        'correspondentAccount', 'settlementAccount', 'bankName', 'legalAddress', 'actualAddress', 'passportSerial',
        'passportNumber', 'passportDate', 'birthday', 'passportIssuedBy', 'userAddress', 'paymentInfoComment',
      ];
      documentsArray = ['inn', 'registration_certificate', 'offer', 'snils', 'passport_page_one', 'passport_page_two'];
    }
    this.setState({ fieldsForAgentType: fields });
    fields.forEach((field) => {
      if (formFields[field].validationType) {
        const { value } = formFields[field];
        formFields[field].errorMessage = getValidation(value, formFields[field].validationType);
        if (formFields[field].errorMessage) {
          validationSuccess = false;
          formFields[field].errorVisible = true;
          if (!errorBlockName) {
            errorBlockName = field;
          }
        }
      }
    });
    documentsArray.forEach((document) => {
      if (documents[document].validationType) {
        const { name } = documents[document];
        documents[document].errorMessage = getValidation(name, documents[document].validationType);
        if (documents[document].errorMessage) {
          validationSuccess = false;
          if (!errorBlockName) {
            errorBlockName = document;
          }
        }
      }
    });
    if (formFields.confirmed.value === 'yes') {
      this.setState({
        formFields, documents,
      }, () => {
        scroller.scrollTo(errorBlockName, {
          duration: 1200,
          delay: 100,
          smooth: true,
          offset: -100,
        });
      });
    }
    return validationSuccess;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { updateAgent } = this.props;
    const { showModalInfo } = this.context;
    const { formFields, documents } = this.state;
    this.setState({ uploading: true });
    const validationSuccess = this.validateAllFields();
    if (validationSuccess || formFields.confirmed.value !== 'yes') {
      const formData = new FormData();
      Object.keys(documents).forEach((document) => {
        if (documents[document].file) {
          formData.append(document, documents[document].file);
        } else {
          formData.append(document, documents[document].path);
        }
      });
      const token = `Bearer ${localStorage.getItem('id_token')}`;
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/profile/update/documents?agentId=${formFields.id.value}`, {
        method: 'post',
        headers: {
          Authorization: token,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((docs) => {
          if (docs.error === null) {
            Object.keys(docs.documents).forEach((document) => {
              if (documents[document] && docs.documents[document]) {
                documents[document].downloaded = true;
                documents[document].errorMessage = null;
                documents[document].path = docs.documents[document];
              }
            });
            this.setState({ documents });

            const { fieldsForAgentType } = this.state;
            const data = Object.fromEntries(
              Object.entries(formFields).map(
                ([name]) => {
                  if (fieldsForAgentType.findIndex((field) => field === name) !== -1) {
                    return [name, formFields[name].value];
                  }
                  return [];
                },
              ),
            );
            customFetch(`${process.env.REACT_APP_API_DOMAIN}/profile/update?agentId=${formFields.id.value}`, {
              method: 'put',
              headers: {
                Authorization: token,
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
              .then((response) => response.json())
              .then((response) => {
                if (!response.error) {
                  updateAgent();
                  showModalInfo('Данные обновлены');
                } else {
                  showModalInfo(response.error);
                }
              })
              .catch(() => {
                showModalInfo('Ошибка');
              })
              .finally(() => {
                this.setState({ uploading: false });
              });
          } else {
            showModalInfo('Ошибка');
          }
        })
        .catch((err) => {
          showModalInfo(`Ошибка ${err}`);
        })
        .finally(() => {
          this.setState({ uploading: false });
        });
    } else {
      this.setState({ uploading: false });
    }
  };

  handleChangeSelect = (event, actionMeta) => {
    const { value } = event;
    const { formFields } = this.state;
    formFields[actionMeta.name].value = value;
    this.setState({ formFields });
  };

  handleChangeOrganizationName = (query) => {
    const { formFields } = this.state;
    formFields.organizationName.value = query.value;
    formFields.inn.value = query.data.inn;
    formFields.kpp.value = query.data.kpp;
    if (formFields.agentTypeCode.value === 'individual') {
      formFields.ogrnip.value = query.data.ogrn;
    }
    if (formFields.agentTypeCode.value === 'legal') {
      formFields.ogrn.value = query.data.ogrn;
    }
    if (query.data.management) {
      formFields.chiefPosition.value = query.data.management.post;
      const fullNameArray = query.data.management.name.split(' ');
      formFields.chiefLastName.value = fullNameArray[0];
      formFields.chiefFirstName.value = fullNameArray[1];
      formFields.chiefMiddleName.value = fullNameArray[2];
      if (formFields.chiefPosition.errorMessage) {
        formFields.chiefPosition.errorVisible = false;
      }
      if (formFields.chiefLastName.errorMessage) {
        formFields.chiefLastName.errorVisible = false;
      }
      if (formFields.chiefFirstName.errorMessage) {
        formFields.chiefFirstName.errorVisible = false;
      }
      if (formFields.chiefMiddleName.errorMessage) {
        formFields.chiefMiddleName.errorVisible = false;
      }
    }
    if (formFields.organizationName.errorMessage) {
      formFields.organizationName.errorVisible = false;
    }
    if (formFields.inn.errorMessage) {
      formFields.inn.errorVisible = false;
    }
    if (formFields.kpp.errorMessage) {
      formFields.kpp.errorVisible = false;
    }
    if (formFields.ogrnip.errorMessage) {
      formFields.ogrnip.errorVisible = false;
    }
    if (formFields.ogrn.errorMessage) {
      formFields.ogrn.errorVisible = false;
    }
    this.setState({ formFields });
  };

  checkSelfEmployed = (e, newRequest = '') => {
    e.preventDefault();
    const { showModalInfo } = this.context;
    const { formFields } = this.state;
    this.setState({ checkingStatusInFNS: true });

    const token = `Bearer ${localStorage.getItem('id_token')}`;

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/check-self-employed/${formFields.id.value}?inn=${formFields.inn.value}&newRequest=${newRequest}`, {
      method: 'get',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          showModalInfo(response.message);
        } else {
          showModalInfo(response.error, 'error');
        }
        if (response.id) {
          this.setState({ alphaBankSelfemployedId: response.id });
        }
      })
      .catch(() => {
        showModalInfo('Ошибка');
      })
      .finally(() => {
        this.setState({ checkingStatusInFNS: false });
      });
  };

  render() {
    const {
      formFields,
      documents,
      checkingStatusInFNS,
      uploading,
      offerBlank,
      alphaBankSelfemployedId,
    } = this.state;
    const { agent, userInfo } = this.props;
    const naturalForm = (
      <>
        <h2>Реквизиты</h2>
        <div className="card mb-3">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-md-3">
                <label htmlFor="inn">ИНН</label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <InputMask
                    value={formFields.inn.value}
                    maskChar=""
                    className={classnames('form-control', { error: formFields.inn.errorVisible })}
                    placeholder="111111111111"
                    name="inn"
                    id="inn"
                    mask={getMask('inn').mask}
                    onBlur={this.handleValidate}
                    onChange={this.handleChangeInput}
                  />
                  {formFields.agentTypeCode.value === 'self_employed' ? (
                    <>
                      {agent.self_employed_verified === 1 && <div className="ml-3 d-inline-block"><FontAwesomeIcon icon={faCheck} className="fa-fw" color="green" size="2x" /></div>}
                      {agent.self_employed_verified === 2 && <div className="ml-3 d-inline-block"><FontAwesomeIcon icon={faTimes} className="fa-fw" color="red" size="2x" /></div>}
                    </>
                  ) : null}
                </div>
                {formFields.inn.errorVisible && (
                  <div className="validation-error">
                    {formFields.inn.errorMessage}
                  </div>
                )}
              </div>
              <div className="col-md-3">
                <label htmlFor="snils">СНИЛС</label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <InputMask
                    value={formFields.snils.value}
                    maskChar=""
                    className={classnames('form-control', { error: formFields.snils.errorVisible })}
                    placeholder="111111111111"
                    name="snils"
                    id="snils"
                    mask={getMask('snils').mask}
                    onBlur={this.handleValidate}
                    onChange={this.handleChangeInput}
                  />
                </div>
                {formFields.snils.errorVisible && (
                  <div className="validation-error">
                    {formFields.snils.errorMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {formFields.agentTypeCode.value === 'self_employed' ? (
          <div className="form-group row">
            <div className="col-2">
              <button disabled={checkingStatusInFNS} className="btn btn-success" type="button" onClick={(e) => this.checkSelfEmployed(e)}>Проверить статус в АльфаБанк</button>
              {checkingStatusInFNS && <LoadingSpinner className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />}
              <p>{alphaBankSelfemployedId}</p>
            </div>
            {userInfo.full_time_employee ? (
              <div className="col-2">
                <button disabled={checkingStatusInFNS} className="btn btn-success" type="button" onClick={(e) => this.checkSelfEmployed(e, true)}>Отправить новый запрос в АльфаБанк</button>
                {checkingStatusInFNS && <LoadingSpinner className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />}
              </div>
            ) : null}
          </div>
        ) : null}
        <h2>Паспортные данные</h2>
        <div className="card mb-3">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-md-3">
                <label htmlFor="passportSerial">Серия</label>
                <InputMask
                  value={formFields.passportSerial.value}
                  maskChar=""
                  className={classnames('form-control', { error: formFields.passportSerial.errorVisible })}
                  placeholder="1111"
                  name="passportSerial"
                  id="passportSerial"
                  mask={getMask('passportSerial').mask}
                  onBlur={this.handleValidate}
                  onChange={this.handleChangeInput}
                />
                {formFields.passportSerial.errorVisible && (
                  <div className="validation-error">
                    {formFields.passportSerial.errorMessage}
                  </div>
                )}
              </div>
              <div className="col-md-3">
                <label htmlFor="passportNumber">Номер</label>
                <InputMask
                  value={formFields.passportNumber.value}
                  maskChar=""
                  className={classnames('form-control', { error: formFields.passportNumber.errorVisible })}
                  placeholder="111111"
                  name="passportNumber"
                  id="passportNumber"
                  mask={getMask('passportNumber').mask}
                  onBlur={this.handleValidate}
                  onChange={this.handleChangeInput}
                />
                {formFields.passportNumber.errorVisible && (
                  <div className="validation-error">
                    {formFields.passportNumber.errorMessage}
                  </div>
                )}
              </div>
              <div className="col-md-3">
                <label htmlFor="passportDate">Дата выдачи</label>
                <DatePicker
                  popperModifiers={{
                    computeStyle: { gpuAcceleration: false },
                  }}
                  onBlur={this.handleValidate}
                  className={classnames('form-control', { error: formFields.passportDate.errorVisible })}
                  selected={formFields.passportDate.value ? parseISO(formFields.passportDate.value) : null}
                  onChange={(date) => this.handleChangeDate(date, 'passportDate')}
                  name="passportDate"
                  id="passportDate"
                  locale={ru}
                  maxDate={new Date()}
                  showYearDropdown
                  showMonthDropdown
                  dateFormat="dd.MM.yyyy"
                  placeholderText="ДД.ММ.ГГГГ"
                  customInput={
                    <InputMask mask="99.99.9999" inputMode="tel" />
                  }
                />
                <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                {formFields.passportDate.errorVisible && (
                  <div className="validation-error">
                    {formFields.passportDate.errorMessage}
                  </div>
                )}
              </div>
              <div className="col-md-3">
                <label htmlFor="birthday">Дата рождения</label>
                <DatePicker
                  popperModifiers={{
                    computeStyle: { gpuAcceleration: false },
                  }}
                  onBlur={this.handleValidate}
                  className={classnames('form-control', { error: formFields.birthday.errorVisible })}
                  selected={formFields.birthday.value ? parseISO(formFields.birthday.value) : null}
                  onChange={(date) => this.handleChangeDate(date, 'birthday')}
                  name="birthday"
                  id="birthday"
                  locale={ru}
                  maxDate={subYears(new Date(), 18)}
                  showYearDropdown
                  showMonthDropdown
                  dateFormat="dd.MM.yyyy"
                  placeholderText="ДД.ММ.ГГГГ"
                  customInput={
                    <InputMask mask="99.99.9999" inputMode="tel" />
                  }
                />
                <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                {formFields.birthday.errorVisible && (
                  <div className="validation-error">
                    {formFields.birthday.errorMessage}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-9">
                <label htmlFor="passportIssuedBy">Кем выдан</label>
                <ReactDadata
                  suggestionType="fms"
                  name="passportIssuedBy"
                  className={classnames('form-control', { error: formFields.passportIssuedBy.errorVisible })}
                  query={formFields.passportIssuedBy.value}
                  token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                  onChange={(e) => this.handleChangePassportIssuedBy(e, 'passportIssuedBy')}
                  placeholder=""
                />
                {formFields.passportIssuedBy.errorVisible && (
                  <div className="validation-error">
                    {formFields.passportIssuedBy.errorMessage}
                  </div>
                )}
              </div>
              <div className="col-lg-3">
                <label htmlFor="passportIssuedByCode">Код подразделения</label>
                <InputMask
                  value={formFields.passportIssuedByCode.value}
                  maskChar=""
                  className={classnames('form-control', { error: formFields.passportIssuedByCode.errorVisible })}
                  placeholder="123-456"
                  name="passportIssuedByCode"
                  id="passportIssuedByCode"
                  onBlur={this.handleValidate}
                  onChange={this.handleChangeInput}
                  mask={getMask('passportUnitNumber').mask}
                  formatChars={getMask('passportUnitNumber').formatChars}
                />
                {formFields.passportIssuedByCode.errorVisible && (
                  <div className="validation-error">
                    {formFields.passportIssuedByCode.errorMessage}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-9">
                <label htmlFor="userAddress">Адрес регистрации</label>
                <ReactDadata
                  name="userAddress"
                  className={classnames('form-control', { error: formFields.userAddress.errorVisible })}
                  query={formFields.userAddress.value ? formFields.userAddress.value.value : ''}
                  token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                  onChange={(e) => this.handleChangeAddress(e, 'userAddress')}
                  placeholder=""
                />
                {formFields.userAddress.errorVisible && (
                  <div className="validation-error">
                    {formFields.userAddress.errorMessage}
                  </div>
                )}
              </div>
              <div className="col-lg-9">
                <label htmlFor="birthdayAddress">Место рождения</label>
                <ReactDadata
                  country="*"
                  from_bound="city"
                  to_bound="settlement"
                  name="birthdayAddress"
                  className={classnames('form-control', { error: formFields.birthdayAddress.errorVisible })}
                  query={formFields.birthdayAddress.value ? formFields.birthdayAddress.value.value : ''}
                  token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                  onChange={(e) => this.handleChangeAddress(e, 'birthdayAddress')}
                  placeholder=""
                />
                {formFields.birthdayAddress.errorVisible && (
                  <div className="validation-error">
                    {formFields.birthdayAddress.errorMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <h2>Банковские реквизиты</h2>
        <div className="card mb-3">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-lg-6">
                <label htmlFor="bankName">Наименование банка</label>
                <ReactDadata
                  suggestionType="bank"
                  name="bankName"
                  className={classnames('form-control', { error: formFields.bankName.errorVisible })}
                  query={formFields.bankName.value}
                  token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                  onChange={this.handleChangeBankName}
                  placeholder="Введите название, БИК или адрес"
                />
                {formFields.bankName.errorVisible && (
                  <div className="validation-error">
                    {formFields.bankName.errorMessage}
                  </div>
                )}
              </div>
              <div className="col-md-6">
                <label htmlFor="paymentCard">Номер карты</label>
                <InputMask
                  value={formFields.paymentCard.value}
                  maskChar=""
                  className={classnames('form-control', { error: formFields.paymentCard.errorVisible })}
                  placeholder="1111 1111 1111 1111"
                  name="paymentCard"
                  id="paymentCard"
                  mask={getMask('paymentCard').mask}
                  onBlur={this.handleValidate}
                  onChange={this.handleChangePaymentCard}
                />
                {formFields.paymentCard.errorVisible && (
                  <div className="validation-error">
                    {formFields.paymentCard.errorMessage}
                  </div>
                )}
              </div>
            </div>
            {(formFields.agentTypeCode.value === 'natural' || formFields.agentTypeCode.value === 'self_employed') ? (
              <div className="form-group row">
                <div className="col-md-3">
                  <label htmlFor="bic">БИК банка</label>
                  <InputMask
                    value={formFields.bic.value}
                    maskChar=""
                    className={classnames('form-control', { error: formFields.bic.errorVisible })}
                    placeholder="111111111"
                    name="bic"
                    id="bic"
                    mask={getMask('bic').mask}
                    onBlur={this.handleValidate}
                    onChange={this.handleChangeInput}
                  />
                  {formFields.bic.errorVisible && (
                    <div className="validation-error">
                      {formFields.bic.errorMessage}
                    </div>
                  )}
                </div>
                <div className="col-md-3">
                  <label htmlFor="correspondentAccount">Корреспондентский счет</label>
                  <InputMask
                    value={formFields.correspondentAccount.value}
                    maskChar=""
                    className={classnames('form-control', { error: formFields.correspondentAccount.errorVisible })}
                    placeholder="111111111"
                    name="correspondentAccount"
                    id="correspondentAccount"
                    mask={getMask('paymentAccount').mask}
                    onBlur={this.handleValidate}
                    onChange={this.handleChangeInput}
                  />
                  {formFields.correspondentAccount.errorVisible && (
                    <div className="validation-error">
                      {formFields.correspondentAccount.errorMessage}
                    </div>
                  )}
                </div>
                <div className="col-md-3">
                  <label htmlFor="settlementAccount">Расчетный счет</label>
                  <InputMask
                    value={formFields.settlementAccount.value}
                    maskChar=""
                    className={classnames('form-control', { error: formFields.settlementAccount.errorVisible })}
                    placeholder="111111111"
                    name="settlementAccount"
                    id="settlementAccount"
                    mask={getMask('paymentAccount').mask}
                    onBlur={this.handleValidate}
                    onChange={this.handleChangeInput}
                  />
                  {formFields.settlementAccount.errorVisible && (
                    <div className="validation-error">
                      {formFields.settlementAccount.errorMessage}
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
    const legalForm = (
      <>
        <h2>Реквизиты</h2>
        <div className="card mb-3">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-md-6">
                <label htmlFor="organizationName">Наименование</label>
                <ReactDadata
                  suggestionType="party"
                  name="organizationName"
                  className={classnames('form-control', { error: formFields.organizationName.errorVisible })}
                  query={formFields.organizationName.value}
                  token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                  onChange={this.handleChangeOrganizationName}
                  placeholder="Введите название, ИНН или ОГРН"
                />
                {formFields.organizationName.errorVisible && (
                  <div className="validation-error">
                    {formFields.organizationName.errorMessage}
                  </div>
                )}
              </div>
              {formFields.agentTypeCode.value === 'legal' && (
                <div className="col-md-3">
                  <label htmlFor="ogrn">ОГРН</label>
                  <InputMask
                    value={formFields.ogrn.value}
                    maskChar=""
                    className={classnames('form-control', { error: formFields.ogrn.errorVisible })}
                    placeholder="1111111111111"
                    name="ogrn"
                    id="ogrn"
                    mask={getMask('ogrn').mask}
                    onBlur={this.handleValidate}
                    onChange={this.handleChangeInput}
                  />
                  {formFields.ogrn.errorVisible && (
                    <div className="validation-error">
                      {formFields.ogrn.errorMessage}
                    </div>
                  )}
                </div>
              )}
              {formFields.agentTypeCode.value === 'individual' && (
                <div className="col-md-3">
                  <label htmlFor="ogrnip">ОГРНИП</label>
                  <InputMask
                    value={formFields.ogrnip.value}
                    maskChar=""
                    className={classnames('form-control', { error: formFields.ogrnip.errorVisible })}
                    placeholder="1111111111111"
                    name="ogrnip"
                    id="ogrnip"
                    mask={getMask('ogrnip').mask}
                    onBlur={this.handleValidate}
                    onChange={this.handleChangeInput}
                  />
                  {formFields.ogrnip.errorVisible && (
                    <div className="validation-error">
                      {formFields.ogrnip.errorMessage}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="form-group row">
              <div className="col-md-3">
                <label htmlFor="inn">{formFields.agentTypeCode.value === 'legal' ? 'ИНН ЮЛ или ЕГРЮЛ' : 'ИНН ИП или ЕГРИП'}</label>
                <InputMask
                  value={formFields.inn.value}
                  maskChar=""
                  className={classnames('form-control', { error: formFields.inn.errorVisible })}
                  placeholder="1111111111"
                  name="inn"
                  id="inn"
                  mask={getMask('inn').mask}
                  onBlur={this.handleValidate}
                  onChange={this.handleChangeInput}
                />
                {formFields.inn.errorVisible && (
                  <div className="validation-error">
                    {formFields.inn.errorMessage}
                  </div>
                )}
              </div>
              {formFields.agentTypeCode.value === 'legal' && (
                <div className="col-md-3">
                  <label htmlFor="kpp">КПП</label>
                  <InputMask
                    value={formFields.kpp.value}
                    maskChar=""
                    className={classnames('form-control', { error: formFields.kpp.errorVisible })}
                    placeholder="111111111"
                    name="kpp"
                    id="kpp"
                    mask={getMask('kpp').mask}
                    onBlur={this.handleValidate}
                    onChange={this.handleChangeInput}
                  />
                  {formFields.kpp.errorVisible && (
                    <div className="validation-error">
                      {formFields.kpp.errorMessage}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {formFields.agentTypeCode.value === 'individual' ? (
          <>
            <h2>Паспортные данные</h2>
            <div className="card mb-3">
              <div className="card-body">
                <div className="form-group row">
                  <div className="col-md-3">
                    <label htmlFor="passportSerial">Серия</label>
                    <InputMask
                      value={formFields.passportSerial.value}
                      maskChar=""
                      className={classnames('form-control', { error: formFields.passportSerial.errorVisible })}
                      placeholder="1111"
                      name="passportSerial"
                      id="passportSerial"
                      mask={getMask('passportSerial').mask}
                      onBlur={this.handleValidate}
                      onChange={this.handleChangeInput}
                    />
                    {formFields.passportSerial.errorVisible && (
                      <div className="validation-error">
                        {formFields.passportSerial.errorMessage}
                      </div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="passportNumber">Номер</label>
                    <InputMask
                      value={formFields.passportNumber.value}
                      maskChar=""
                      className={classnames('form-control', { error: formFields.passportNumber.errorVisible })}
                      placeholder="111111"
                      name="passportNumber"
                      id="passportNumber"
                      mask={getMask('passportNumber').mask}
                      onBlur={this.handleValidate}
                      onChange={this.handleChangeInput}
                    />
                    {formFields.passportNumber.errorVisible && (
                      <div className="validation-error">
                        {formFields.passportNumber.errorMessage}
                      </div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="passportDate">Дата выдачи</label>
                    <DatePicker
                      popperModifiers={{
                        computeStyle: { gpuAcceleration: false },
                      }}
                      onBlur={this.handleValidate}
                      className={classnames('form-control', { error: formFields.passportDate.errorVisible })}
                      selected={formFields.passportDate.value ? parseISO(formFields.passportDate.value) : null}
                      onChange={(date) => this.handleChangeDate(date, 'passportDate')}
                      name="passportDate"
                      id="passportDate"
                      locale={ru}
                      maxDate={new Date()}
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="dd.MM.yyyy"
                      placeholderText="ДД.ММ.ГГГГ"
                      customInput={
                        <InputMask mask="99.99.9999" inputMode="tel" />
                      }
                    />
                    <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                    {formFields.passportDate.errorVisible && (
                      <div className="validation-error">
                        {formFields.passportDate.errorMessage}
                      </div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="birthday">Дата рождения</label>
                    <DatePicker
                      popperModifiers={{
                        computeStyle: { gpuAcceleration: false },
                      }}
                      onBlur={this.handleValidate}
                      className={classnames('form-control', { error: formFields.birthday.errorVisible })}
                      selected={formFields.birthday.value ? parseISO(formFields.birthday.value) : null}
                      onChange={(date) => this.handleChangeDate(date, 'birthday')}
                      name="birthday"
                      id="birthday"
                      locale={ru}
                      maxDate={subYears(new Date(), 18)}
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="dd.MM.yyyy"
                      placeholderText="ДД.ММ.ГГГГ"
                      customInput={
                        <InputMask mask="99.99.9999" inputMode="tel" />
                      }
                    />
                    <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                    {formFields.birthday.errorVisible && (
                      <div className="validation-error">
                        {formFields.birthday.errorMessage}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-9">
                    <label htmlFor="passportIssuedBy">Кем выдан</label>
                    <ReactDadata
                      suggestionType="fms"
                      name="passportIssuedBy"
                      className={classnames('form-control', { error: formFields.passportIssuedBy.errorVisible })}
                      query={formFields.passportIssuedBy.value}
                      token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                      onChange={(e) => this.handleChangePassportIssuedBy(e, 'passportIssuedBy')}
                      placeholder=""
                    />
                    {formFields.passportIssuedBy.errorVisible && (
                      <div className="validation-error">
                        {formFields.passportIssuedBy.errorMessage}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-9">
                    <label htmlFor="userAddress">Адрес регистрации</label>
                    <ReactDadata
                      name="userAddress"
                      className={classnames('form-control', { error: formFields.userAddress.errorVisible })}
                      query={formFields.userAddress.value ? formFields.userAddress.value.value : ''}
                      token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                      onChange={(e) => this.handleChangeAddress(e, 'userAddress')}
                      placeholder=""
                    />
                    {formFields.userAddress.errorVisible && (
                      <div className="validation-error">
                        {formFields.userAddress.errorMessage}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        <h2>Юридический адрес</h2>
        <div className="card mb-3">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-lg-9">
                <ReactDadata
                  name="legalAddress"
                  className={classnames('form-control', { error: formFields.legalAddress.errorVisible })}
                  query={formFields.legalAddress.value ? formFields.legalAddress.value.value : ''}
                  token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                  onChange={(e) => this.handleChangeAddress(e, 'legalAddress')}
                  placeholder=""
                />
                {formFields.legalAddress.errorVisible && (
                  <div className="validation-error">
                    {formFields.legalAddress.errorMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <h2>Фактический адрес</h2>
        <div className="card mb-3">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-lg-12">
                <div className="form-check custom-checkbox">
                  <input
                    onChange={this.handleSetActualMatchLegal}
                    checked={formFields.actualMatchLegal.value}
                    className="form-check-input checkbox-styled"
                    type="checkbox"
                    name="actualMatchLegal"
                    id="actualMatchLegal"
                  />
                  <label className="form-check-label" htmlFor="actualMatchLegal">
                    Совпадает с юридическим адресом
                  </label>
                </div>
              </div>
            </div>
            {!formFields.actualMatchLegal.value && (
              <div className="form-group row">
                <div className="col-lg-9">
                  <ReactDadata
                    name="actualAddress"
                    className={classnames('form-control', { error: formFields.actualAddress.errorVisible })}
                    query={formFields.actualAddress.value ? formFields.actualAddress.value.value : ''}
                    token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                    onChange={(e) => this.handleChangeAddress(e, 'actualAddress')}
                    placeholder=""
                  />
                  {formFields.actualAddress.errorVisible && (
                    <div className="validation-error">
                      {formFields.actualAddress.errorMessage}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <h2>Банковские реквизиты</h2>
        <div className="card mb-3">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-lg-9">
                <label htmlFor="bankName">Наименование банка</label>
                <ReactDadata
                  suggestionType="bank"
                  name="bankName"
                  className={classnames('form-control', { error: formFields.bankName.errorVisible })}
                  query={formFields.bankName.value}
                  token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                  onChange={this.handleChangeBankName}
                  placeholder="Введите название, БИК или адрес"
                />
                {formFields.bankName.errorVisible && (
                  <div className="validation-error">
                    {formFields.bankName.errorMessage}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-md-3">
                <label htmlFor="bic">БИК банка</label>
                <InputMask
                  value={formFields.bic.value}
                  maskChar=""
                  className={classnames('form-control', { error: formFields.bic.errorVisible })}
                  placeholder="111111111"
                  name="bic"
                  id="bic"
                  mask={getMask('bic').mask}
                  onBlur={this.handleValidate}
                  onChange={this.handleChangeInput}
                />
                {formFields.bic.errorVisible && (
                  <div className="validation-error">
                    {formFields.bic.errorMessage}
                  </div>
                )}
              </div>
              <div className="col-md-3">
                <label htmlFor="correspondentAccount">Корреспондентский счет</label>
                <InputMask
                  value={formFields.correspondentAccount.value}
                  maskChar=""
                  className={classnames('form-control', { error: formFields.correspondentAccount.errorVisible })}
                  placeholder="111111111"
                  name="correspondentAccount"
                  id="correspondentAccount"
                  mask={getMask('paymentAccount').mask}
                  onBlur={this.handleValidate}
                  onChange={this.handleChangeInput}
                />
                {formFields.correspondentAccount.errorVisible && (
                  <div className="validation-error">
                    {formFields.correspondentAccount.errorMessage}
                  </div>
                )}
              </div>
              <div className="col-md-3">
                <label htmlFor="settlementAccount">Расчетный счет</label>
                <InputMask
                  value={formFields.settlementAccount.value}
                  maskChar=""
                  className={classnames('form-control', { error: formFields.settlementAccount.errorVisible })}
                  placeholder="111111111"
                  name="settlementAccount"
                  id="settlementAccount"
                  mask={getMask('paymentAccount').mask}
                  onBlur={this.handleValidate}
                  onChange={this.handleChangeInput}
                />
                {formFields.settlementAccount.errorVisible && (
                  <div className="validation-error">
                    {formFields.settlementAccount.errorMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {formFields.agentTypeCode.value === 'legal' && (
          <>
            <h2>Руководитель организации</h2>
            <div className="card mb-3">
              <div className="card-body">
                <div className="form-group row">
                  <div className="col-md-3">
                    <label htmlFor="chiefPosition">Должность руководителя</label>
                    <input
                      value={formFields.chiefPosition.value}
                      className={classnames('form-control', { error: formFields.chiefPosition.errorVisible })}
                      placeholder="Директор"
                      name="chiefPosition"
                      id="chiefPosition"
                      onBlur={this.handleValidate}
                      onChange={this.handleChangeInput}
                    />
                    {formFields.chiefPosition.errorVisible && (
                      <div className="validation-error">
                        {formFields.chiefPosition.errorMessage}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-3">
                    <label htmlFor="chiefLastName">Фамилия</label>
                    <InputMask
                      formatChars={getMask('name').formatChars}
                      value={formFields.chiefLastName.value}
                      maskChar=""
                      className={classnames('form-control', { error: formFields.chiefLastName.errorVisible })}
                      placeholder="Иванов"
                      name="chiefLastName"
                      id="chiefLastName"
                      mask={getMask('name').mask}
                      onBlur={this.handleValidate}
                      onChange={(e) => this.handleChangeInput(e, true)}
                    />
                    {formFields.chiefLastName.errorVisible && (
                      <div className="validation-error">
                        {formFields.chiefLastName.errorMessage}
                      </div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="chiefFirstName">Имя</label>
                    <InputMask
                      formatChars={getMask('name').formatChars}
                      value={formFields.chiefFirstName.value}
                      maskChar=""
                      className={classnames('form-control', { error: formFields.chiefFirstName.errorVisible })}
                      placeholder="Иван"
                      name="chiefFirstName"
                      id="chiefFirstName"
                      mask={getMask('name').mask}
                      onBlur={this.handleValidate}
                      onChange={(e) => this.handleChangeInput(e, true)}
                    />
                    {formFields.chiefFirstName.errorVisible && (
                      <div className="validation-error">
                        {formFields.chiefFirstName.errorMessage}
                      </div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="chiefMiddleName">Отчество</label>
                    <InputMask
                      formatChars={getMask('name').formatChars}
                      value={formFields.chiefMiddleName.value}
                      maskChar=""
                      className={classnames('form-control', { error: formFields.chiefMiddleName.errorVisible })}
                      placeholder="Иванович"
                      name="chiefMiddleName"
                      id="chiefMiddleName"
                      mask={getMask('name').mask}
                      onBlur={this.handleValidate}
                      onChange={(e) => this.handleChangeInput(e, true)}
                    />
                    {formFields.chiefMiddleName.errorVisible && (
                      <div className="validation-error">
                        {formFields.chiefMiddleName.errorMessage}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-3">
                    <label htmlFor="passportSerial">Серия</label>
                    <InputMask
                      value={formFields.chiefPassportSerial.value}
                      disabled={agent.confirmed !== 'no' && agent.confirmed !== 'need_update' && agent.confirmed !== 'no_need'}
                      maskChar=""
                      className={classnames('form-control', { error: formFields.chiefPassportSerial.errorVisible })}
                      placeholder="1111"
                      name="chiefPassportSerial"
                      id="chiefPassportSerial"
                      mask={getMask('passportSerial').mask}
                      onBlur={this.handleValidate}
                      onChange={this.handleChangeInput}
                    />
                    {formFields.chiefPassportSerial.errorVisible && (
                      <div className="validation-error">
                        {formFields.chiefPassportSerial.errorMessage}
                      </div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="chiefPassportNumber">Номер</label>
                    <InputMask
                      value={formFields.chiefPassportNumber.value}
                      disabled={agent.confirmed !== 'no' && agent.confirmed !== 'need_update' && agent.confirmed !== 'no_need'}
                      maskChar=""
                      className={classnames('form-control', { error: formFields.chiefPassportNumber.errorVisible })}
                      placeholder="111111"
                      name="chiefPassportNumber"
                      id="chiefPassportNumber"
                      mask={getMask('passportNumber').mask}
                      onBlur={this.handleValidate}
                      onChange={this.handleChangeInput}
                    />
                    {formFields.chiefPassportNumber.errorVisible && (
                      <div className="validation-error">
                        {formFields.chiefPassportNumber.errorMessage}
                      </div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="chiefPassportDate">Дата выдачи</label>
                    <DatePicker
                      popperModifiers={{
                        computeStyle: { gpuAcceleration: false },
                      }}
                      disabled={agent.confirmed !== 'no' && agent.confirmed !== 'need_update' && agent.confirmed !== 'no_need'}
                      onBlur={this.handleValidate}
                      className={classnames('form-control', { error: formFields.chiefPassportDate.errorVisible })}
                      selected={formFields.chiefPassportDate.value ? parseISO(formFields.chiefPassportDate.value) : null}
                      onChange={(date) => this.handleChangeDate(date, 'chiefPassportDate')}
                      name="chiefPassportDate"
                      id="chiefPassportDate"
                      locale={ru}
                      maxDate={new Date()}
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="dd.MM.yyyy"
                      placeholderText="ДД.ММ.ГГГГ"
                      customInput={
                        <InputMask mask="99.99.9999" inputMode="tel" />
                      }
                    />
                    <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                    {formFields.chiefPassportDate.errorVisible && (
                      <div className="validation-error">
                        {formFields.chiefPassportDate.errorMessage}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-9">
                    <label htmlFor="chiefPassportIssuedBy">Кем выдан</label>
                    <ReactDadata
                      suggestionType="fms"
                      name="passportIssuedBy"
                      className={classnames('form-control', { error: formFields.chiefPassportIssuedBy.errorVisible })}
                      query={formFields.chiefPassportIssuedBy.value}
                      token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                      onChange={(e) => this.handleChangePassportIssuedBy(e, 'chiefPassportIssuedBy')}
                      placeholder=""
                      disabled={agent.confirmed !== 'no' && agent.confirmed !== 'need_update' && agent.confirmed !== 'no_need'}
                    />
                    {formFields.chiefPassportIssuedBy.errorVisible && (
                      <div className="validation-error">
                        {formFields.chiefPassportIssuedBy.errorMessage}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-3">
                    <label htmlFor="chiefEmail">Email</label>
                    <input
                      value={formFields.chiefEmail.value}
                      className={classnames('form-control', { error: formFields.chiefEmail.errorVisible })}
                      placeholder="director@gmail.com"
                      name="chiefEmail"
                      id="chiefEmail"
                      onBlur={this.handleValidate}
                      onChange={this.handleChangeInput}
                    />
                    {formFields.chiefEmail.errorVisible && (
                      <div className="validation-error">
                        {formFields.chiefEmail.errorMessage}
                      </div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="chiefPhone">Телефон</label>
                    <InputMask
                      formatChars={getMask('phone').formatChars}
                      value={formFields.chiefPhone.value}
                      maskChar=""
                      className={classnames('form-control', { error: formFields.chiefPhone.errorVisible })}
                      placeholder="+7 (911) 999-99-99"
                      name="chiefPhone"
                      id="chiefPhone"
                      mask={getMask('phone').mask}
                      onBlur={this.handleValidate}
                      onChange={this.handleChangeInput}
                    />
                    {formFields.chiefPhone.errorVisible && (
                      <div className="validation-error">
                        {formFields.chiefPhone.errorMessage}
                      </div>
                    )}
                  </div>
                  {agent.full_time_employee ? (
                    <div className="col-md-3">
                      <label htmlFor="chiefEmail">Ссылка на CRM</label>
                      <input
                        value={formFields.chiefLinkToCrm.value}
                        className={classnames('form-control', { error: formFields.chiefLinkToCrm.errorVisible })}
                        placeholder="Ссылка на лид в CRM"
                        name="chiefLinkToCrm"
                        id="chiefLinkToCrm"
                        onChange={this.handleChangeInput}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="card mb-3">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-lg-3">
                <label>Статус платежной информации</label>
                <Select
                  classNamePrefix="react-select"
                  styles={customSelectStyle()}
                  name="confirmed"
                  placeholder="Статус"
                  id="confirmed"
                  value={optionConfirmed.filter(({ value }) => value === formFields.confirmed.value)}
                  options={optionConfirmed}
                  onChange={this.handleChangeSelect}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-3">
                <label htmlFor="agentType">Тип</label>
                <Select
                  classNamePrefix="react-select"
                  styles={customSelectStyle()}
                  name="agentTypeCode"
                  isSearchable={false}
                  placeholder="Выберите тип"
                  id="agentTypeCode"
                  value={optionType.filter(({ value }) => value === formFields.agentTypeCode.value)}
                  onChange={this.handleChangeSelect}
                  options={optionType}
                />
              </div>
            </div>
          </div>
        </div>
        {(formFields.agentTypeCode.value === 'natural' || formFields.agentTypeCode.value === 'self_employed') ? naturalForm : legalForm }
        <h2>Документы</h2>
        <div className="card mb-3">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-lg-5">
                <label className="d-block" htmlFor="passport_page_one">{formFields.agentTypeCode.value === 'legal' ? 'Фото паспорта руководителя с датой рождения' : 'Фото паспорта с датой рождения'}</label>
                <div className="jq-file styler">
                  <div className="jq-file__name">{documents.passport_page_one.downloaded ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></> : documents.passport_page_one.name}</div>
                  {(documents.passport_page_one.path || documents.passport_page_one.file)
                    ? (<div onClick={() => this.handleDeleteFile('passport_page_one')} className="jq-file__remove" />)
                    : (<div className="jq-file__browse" />)}
                  <input onChange={this.handleChangeFile} type="file" id="passport_page_one" name="passport_page_one" className="custom-input-file" />
                </div>
                {documents.passport_page_one.path && (
                <div className="jq-file__download">
                  <a aria-label="Скачать" rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${documents.passport_page_one.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
                </div>
                )}
                {documents.passport_page_one.errorMessage && (
                <div className="validation-error">
                  {documents.passport_page_one.errorMessage}
                </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-5">
                <label className="d-block" htmlFor="passport_page_two">{formFields.agentTypeCode.value === 'legal' ? 'Фото паспорта руководителя с адресом регистрации' : 'Фото паспорта с адресом регистрации'}</label>
                <div className="jq-file styler">
                  <div className="jq-file__name">{documents.passport_page_two.downloaded ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></> : documents.passport_page_two.name}</div>
                  {(documents.passport_page_two.path || documents.passport_page_two.file)
                    ? (<div onClick={() => this.handleDeleteFile('passport_page_two')} className="jq-file__remove" />)
                    : (<div className="jq-file__browse" />)}
                  <input onChange={this.handleChangeFile} type="file" id="passport_page_two" name="passport_page_two" className="custom-input-file" />
                </div>
                {documents.passport_page_two.path && (
                <div className="jq-file__download">
                  <a aria-label="Скачать" rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${documents.passport_page_two.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
                </div>
                )}
                {documents.passport_page_two.errorMessage && (
                <div className="validation-error">
                  {documents.passport_page_two.errorMessage}
                </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-5">
                <label className="d-block" htmlFor="innDocument">ИНН</label>
                <div className="jq-file styler">
                  <div className="jq-file__name">{documents.inn.downloaded ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></> : documents.inn.name}</div>
                  {(documents.inn.path || documents.inn.file)
                    ? (<div onClick={() => this.handleDeleteFile('inn')} className="jq-file__remove" />)
                    : (<div className="jq-file__browse" />)}
                  <input onChange={this.handleChangeFile} type="file" id="innDocument" name="inn" className="custom-input-file" />
                </div>
                {documents.inn.path && (
                <div className="jq-file__download">
                  <a aria-label="Скачать" rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${documents.inn.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
                </div>
                )}
                {documents.inn.errorMessage && (
                <div className="validation-error">
                  {documents.inn.errorMessage}
                </div>
                )}
              </div>
            </div>
            {(formFields.agentTypeCode.value === 'natural' || formFields.agentTypeCode.value === 'individual' || formFields.agentTypeCode.value === 'self_employed') && (
            <div className="form-group row">
              <div className="col-lg-5">
                <label className="d-block" htmlFor="snils">СНИЛС</label>
                <div className="jq-file styler">
                  <div className="jq-file__name">{documents.snils.downloaded ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></> : documents.snils.name}</div>
                  {(documents.snils.path || documents.snils.file)
                    ? (<div onClick={() => this.handleDeleteFile('snils')} className="jq-file__remove" />)
                    : (<div className="jq-file__browse" />)}
                  <input onChange={this.handleChangeFile} type="file" id="snils" name="snils" className="custom-input-file" />
                </div>
                {documents.snils.path && (
                <div className="jq-file__download">
                  <a aria-label="Скачать" rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${documents.snils.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
                </div>
                )}
                {documents.snils.errorMessage && (
                <div className="validation-error">
                  {documents.snils.errorMessage}
                </div>
                )}
              </div>
            </div>
            )}
            {(formFields.agentTypeCode.value === 'legal' || formFields.agentTypeCode.value === 'individual') && (
            <>
              <div className="form-group row">
                <div className="col-lg-5">
                  <label className="d-block" htmlFor="registration_certificate">{formFields.agentTypeCode.value === 'legal' ? 'Свидетельство о регистрации ЮЛ' : 'Свидетельство о регистрации ИП'}</label>
                  <div className="jq-file styler">
                    <div className="jq-file__name">{documents.registration_certificate.downloaded ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></> : documents.registration_certificate.name}</div>
                    {(documents.registration_certificate.path || documents.registration_certificate.file)
                      ? (<div onClick={() => this.handleDeleteFile('registration_certificate')} className="jq-file__remove" />)
                      : (<div className="jq-file__browse" />)}
                    <input onChange={this.handleChangeFile} type="file" id="registration_certificate" name="registration_certificate" className="custom-input-file" />
                  </div>
                  {documents.registration_certificate.path && (
                  <div className="jq-file__download">
                    <a aria-label="Скачать" rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${documents.registration_certificate.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
                  </div>
                  )}
                  {documents.registration_certificate.errorMessage && (
                  <div className="validation-error">
                    {documents.registration_certificate.errorMessage}
                  </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-5">
                  <label className="d-block" htmlFor="offer">Заявление на присоединение к Договору-оферте</label>
                  <div className="jq-file styler">
                    <div className="jq-file__name">{documents.offer.downloaded ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></> : documents.offer.name}</div>
                    {(documents.offer.path || documents.offer.file)
                      ? (<div onClick={() => this.handleDeleteFile('offer')} className="jq-file__remove" />)
                      : (<div className="jq-file__browse" />)}
                    <input onChange={this.handleChangeFile} type="file" id="offer" name="offer" className="custom-input-file" />
                  </div>
                  {documents.offer.path && (
                  <div className="jq-file__download">
                    <a aria-label="Скачать" rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${documents.offer.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
                  </div>
                  )}
                  {documents.offer.errorMessage && (
                  <div className="validation-error">
                    {documents.offer.errorMessage}
                  </div>
                  )}
                </div>
                <div className="col-lg-4 mt-2 mt-md-0">
                  <label className="hidden d-none d-lg-block">hide</label>
                  <a type="button" rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${offerBlank}`} className="btn custom-btn">Скачать форму</a>
                </div>
              </div>
            </>
            )}
            {(formFields.agentTypeCode.value === 'self_employed') && (
            <div className="form-group row">
              <div className="col-lg-5">
                <label className="d-block" htmlFor="self_employed_document">Справка о постановке на учет самозанятого</label>
                <div className="jq-file styler">
                  <div className="jq-file__name">{documents.self_employed_document.downloaded ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></> : documents.self_employed_document.name}</div>
                  {(documents.self_employed_document.path || documents.self_employed_document.file)
                    ? (<div onClick={() => this.handleDeleteFile('self_employed_document')} className="jq-file__remove" />)
                    : (<div className="jq-file__browse" />)}
                  <input onChange={this.handleChangeFile} type="file" id="self_employed_document" name="self_employed_document" className="custom-input-file" />
                </div>
                {documents.self_employed_document.path && (
                <div className="jq-file__download">
                  <a aria-label="Скачать" rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${documents.self_employed_document.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
                </div>
                )}
                {documents.self_employed_document.errorMessage && (
                <div className="validation-error">
                  {documents.self_employed_document.errorMessage}
                </div>
                )}
              </div>
            </div>
            )}
            <div className="form-group row">
              <div className="col-lg-6">
                <label htmlFor="paymentInfoComment">Комментарий по уточнению платежных данных (от куратора)</label>
                <AutoResizingTextarea
                  disabled={agent.confirmed !== 'no' && agent.confirmed !== 'need_update' && agent.confirmed !== 'no_need'}
                  onBlur={this.handleValidate}
                  onChange={this.handleChangeInput}
                  id="paymentInfoComment"
                  placeholder="Оставьте свой комментарий здесь"
                  name="paymentInfoComment"
                  className={classnames('form-control', { error: formFields.paymentInfoComment.errorVisible })}
                  value={formFields.paymentInfoComment.value}
                />
                {formFields.paymentInfoComment.errorVisible && (
                  <div className="validation-error">
                    {formFields.paymentInfoComment.errorMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <button disabled={uploading} className="btn btn-success" type="submit">Сохранить</button>
        {uploading && <LoadingSpinner className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />}
      </form>
    );
  }
}

PaymentInfo.contextType = ModalMessagesContext;

export default withUserInfoStoreStore(PaymentInfo);
